<template>
  <div class="Orderlistpar">
    <div class="top-titles">
      <div class="title">
          <span></span>修改密码
      </div>
    </div>

    <!-- 详情-->
    <div class="particulars">
      <div class="particulars-box">
          <div class="s-list"><span class="pl-tltle"></span><span class="pl-content"></span></div>
          <div class="s-list"><span class="pl-tltle"></span><span class="pl-content"></span></div>
          <div class="s-list"><span class="pl-tltle">重置密码:</span>
          <span class="pl-content">  
            <el-form ref="form">
            <el-form-item label="">
            <el-input v-model="listpar.password"></el-input>
          </el-form-item>
            </el-form>       
        </span></div>
        <div class="s-list" ><el-button type="info" @click="bianji()" >修改</el-button></div>
      </div>


    </div>
  </div>
</template>
<script>
import fakelist from "@/api/fakelist.js"
import apiurl from "@/api/apiurl/apiurl.js"
import config from "@/request/config.js"
export default {
  data() {
    return {
           // 上传图片的地址
      upLoadUrl:config.ImgUrl,
      listpar:{},

    };
  },

  created(){
  },
  mounted() {},

  methods: {



    // 修改密码
  async  bianji(){
      	let res = await apiurl.entityDataHandle({
        FOREND_TOKEN:this.$common.baseGetToken(),//统一传递一下前端token 
        loginName:this.$common.baseGetuSername(),//登录账号 
        loginType:this.$common.baseGetSeparate(),//区分用户和商家1用户2商家
        bizCatalog:'AAAEntity',
        handleMode:'resetSelfPwd',
        newPasswd:this.listpar.password,
         })
        if(this.$common.verifyAjaxResult(res,false)==false) return;
         this.$alert('修改成功', '消息提示', {confirmButtonText: '确定'});
    },
  },
  computed: {},
};
</script>


<style  scoped>
.top-titles{
    width: 94%;
    margin: 0 auto;
    height: 100px ;
}
.top-titles .title{
        font-size: 20px;
        height: 30px;
        font-weight: 600;
        color: #555;
}
.top-titles .title span:nth-of-type(1){font-size: 18px;}
.top-titles .title span{
    font-weight: 200;
    color: #999;
}
.particulars{
   width: 100%;
   padding-top: 50px;
}
.particulars-box{
    width: 94%;
    margin: 0 auto;

}
.s-list {
    width: 370px;
    margin: 0 auto;
    height: 60px;
    padding-right: 10px;
}
.s-list:nth-of-type(3){
  position: relative;
  left: 0px;
}
.s-list .pl-tltle{
    font-size: 14px;
    color: #777;

}
.s-list .pl-content{
    font-size: 14px;
    padding-left: 10px;
    color: #222;
}
.s-list:nth-of-type(3) .pl-content{
    width: 250px;
    display: inline-block;
}
.s-list:nth-of-type(4) {
    width: 100px;
   margin: 0 auto;}
</style>